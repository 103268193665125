import React from 'react';
class RouteComponent extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div>
               {this.props.selectedTab} page is curretnly under construction
            </div>
        )
    }
}

export default RouteComponent;