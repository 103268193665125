import React from 'react';
import RouteComponent from './components/RouteComponent';
import './App.css';
import { Link, Switch, Route } from 'react-router-dom';
import RegularComponent from './components/RegularComponent';
import MorningComponent from './components/MorningComponent';

const Error =()=> <div>Oops! Page not found!</div>;
class App extends React.Component {
  constructor(props){
    var selectedTab = "regular";
    super(props);
    const index = props.location.pathname.indexOf("/home");
    if (index + 5 >= props.location.pathname.length){
      this.selectedTab = "regular";
    } else {
      switch (props.location.pathname.slice(index+5)) {
        case "/regular":
          this.selectedTab = "regular";
          break;
        case "/random":
          this.selectedTab = "random";
          break;
        case "/stretch":
          this.selectedTab = "stretch";
          break;
        case "/position":
          this.selectedTab = "position";
          break;
        case "/morning":
          this.selectedTab = "morning";
          break;
        default:
          this.selectedTab = "regular";
          break;
      }
    }
  }

  onTabSelect = e =>{
    this.selectedTab = e;
  }

  render(){
    const {path} = this.props.match;
    return (
    <div className="col">
      <div>
      <div className="nav nav-tabs  nav-pills nav-fill" id="myTab" role="tablist">
     
        <Link className={"nav-item nav-link"} to={`${path}/regular`} onClick={()=>this.onTabSelect('regular')}>Regular</Link>
        <Link className={"nav-item nav-link"} to={`${path}/morning`} onClick={()=>this.onTabSelect('morning')}>Morning Exercise</Link>
        <Link className={"nav-item nav-link"} to={`${path}/random`} onClick={()=>this.onTabSelect('random')}>Random Generator</Link>
        <Link className={"nav-item nav-link"} to={`${path}/stretch`}onClick={()=>this.onTabSelect('stretch')}>Stretch</Link>
        <Link className={"nav-item nav-link"} to={`${path}/position`}onClick={()=>this.onTabSelect('position')}>Correct position</Link>
        <span className="navbar-text"> Let's workout!</span>
      </div>
      <div>
        <Switch>
          <Route path={`${path}`} exact render={(props) => <RegularComponent {...props} selectedTab = {this.selectedTab}/>}/>
          <Route path={`${path}/regular`} exact render={(props) => <RegularComponent {...props} selectedTab = {this.selectedTab}/>}/>
          <Route path={`${path}/morning`} exact render={(props) => <MorningComponent {...props} selectedTab = {this.selectedTab}/>}/>
          <Route path={`${path}/random`} exact render={(props) => <RouteComponent {...props} selectedTab = {this.selectedTab}/>}/>
          <Route path={`${path}/stretch`} exact render={(props) => <RouteComponent {...props} selectedTab = {this.selectedTab}/>}/>
          <Route path={`${path}/position`} exact render={(props) => <RouteComponent {...props} selectedTab = {this.selectedTab}/>}/>

          <Route component={Error}/>

        </Switch>
      </div>
      </div>
    </div>
    );
  }
}

export default App;
