export const list=[{
    'title':'Abs Day1', 
    'length':'40mins',
    'id':'ab1',
    'list': [{
    'id':1,
    'title':'Warm up',
    'url':'https://youtu.be/-p0PA9Zt8zk',
    'duration': 6
    },{
        'id':2,
        'title':'14 mins full body workout',
        'url':'https://youtu.be/ge1ALhE-Fqs',
        'duration': 14
    },{
        'id':3,
        'title':'10 mins Abs',
        'url':'https://youtu.be/rPPu5RqB_TU',
        'duration': 10
    },{
        'id':4,
        'title':'Cooldown',
        'url':'https://www.youtube.com/watch?v=tXWh-dowiLg',
        'duration': 10
    }]
}]