import React from 'react';
import ShowRandomPicture from './RandomPicture';
import { list } from '../util/constants/WorkoutList';
class RegularComponent extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div>
               <h2 className="text-center text-info">Regular preset plan</h2>
     
               <div className="row">
                    <div className="col-2">
                        <div className="list-group" id="myList" role="tablist">
                        <a className="list-group-item list-group-item-action" data-toggle="tab" href="#1" role="tab">Day1</a>
                        <a className="list-group-item list-group-item-action"  data-toggle="tab" href="#2" role="tab">Day2</a>
                        <a className="list-group-item list-group-item-action"  data-toggle="tab" href="#3" role="tab">Day3</a>
                        <a className="list-group-item list-group-item-action"  data-toggle="tab" href="#4" role="tab">Day4</a>
                        <a className="list-group-item list-group-item-action" data-toggle="tab" href="#ab1" role="tab">Abs Day1</a>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="tab-content">  
                        
                            <div className="tab-pane " id="1" role="tabpanel" >
                                <div className="row"><h4>In total: 47 mins</h4></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=Lscqihirc3c&list=PLAFs3kxY4h1-r1dysC5AeXT5D_TfXsBfr&index=1" target="_blank">25min full body fat</a> (warm up included)</div>             
                                <div className="row"><a href="https://www.youtube.com/watch?v=3Pr6n-nKfMA&list=PLAFs3kxY4h1_IiQSAbZiC390Bwd5NqMFQ" target="_blank">12mins tight core + arms</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=tXWh-dowiLg" target="_blank">10mins cool down stretches</a></div>
                            </div>
                            <div className="tab-pane " id="2" role="tabpanel" >
                                <div className="row"><h4>In total: 52 mins</h4></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=-p0PA9Zt8zk" target="_blank">6mins warm up</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=CGmr02bfHUo&list=PLAFs3kxY4h190VrR7w4aCXJXU5-vWt-0w" target="_blank">20mins full body workout to lose fat</a></div>             
                                <div className="row"><a href="https://www.youtube.com/watch?v=cIuiQyfKBTg&list=PLAFs3kxY4h19jbKjLS9vmDF2W53RZUfAg" target="_blank">16mins tiny waist + round butt</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=tXWh-dowiLg" target="_blank">10mins cool down stretches</a></div>
                            </div>
                            <div className="tab-pane " id="3" role="tabpanel">
                                <div className="row"> <h4>In total: 47 mins</h4></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=-p0PA9Zt8zk" target="_blank">6mins warm up</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=mvlfZ43ehmE&list=PLAFs3kxY4h1-r1dysC5AeXT5D_TfXsBfr&index=3" target="_blank">15mins HIIT workout for flat tummy</a></div>             
                                <div className="row"><a href="https://www.youtube.com/watch?v=EUruBzhv7Kk&list=PLAFs3kxY4h1-kLMG2F9GJBg4cqr-i7T8w" target="_blank">16mins slim thighs + legs</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=tXWh-dowiLg" target="_blank">10mins cool down stretches</a></div>
                            </div>
                            <div className="tab-pane" id="4" role="tabpanel">     
                                <div className="row"><h4>In total: 56 mins</h4></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=Lscqihirc3c&list=PLAFs3kxY4h1-r1dysC5AeXT5D_TfXsBfr&index=1" target="_blank">25min full body fat</a> (warm up included)</div>             
                                <div className="row"><a href="https://www.youtube.com/watch?v=d8STqg1VvN0&list=PLAFs3kxY4h1-r1dysC5AeXT5D_TfXsBfr&index=5" target="_blank">10mins arm+back</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=6TmQiugy_qw&list=PLAFs3kxY4h1-r1dysC5AeXT5D_TfXsBfr&index=2" target="_blank">11mins abs</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=tXWh-dowiLg" target="_blank">10mins cool down stretches</a></div>
                            </div> 
                               {/* <div className="tab-pane " id="ab1" role="tabpanel" >

                                    <div className="row"><h4>In total: 40 mins</h4></div>
                                    {list.map((item, i) => {
                                    return(<div className="row" key={i}><a href={item.url} target="_blank">{item.title}</a></div>   )
                                    })}
                            </div>    */}
                             {list.map((item, i) => {
                                 return (<div className="tab-pane " id={item.id} role="tabpanel" >
                                            <div className="row"><h4>In total: {item.length}</h4></div>  
                                            {item.list.map((workout, j)=>{
                                                return (<div className="row" key={j}><a href={workout.url} target="_blank">{workout.title}</a></div>)
                                            })}
                                        </div>)
                             })}
                              
                        </div>
                    </div>
                </div> 
                <ShowRandomPicture/>
            </div>
        )
    }
}

export default RegularComponent;