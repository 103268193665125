import React from 'react';
import ShowRandomPicture from './RandomPicture';
class MorningComponent extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <div>
               <h2 className="text-center text-success">Good morning</h2>

               <div className="row">
                    <div className="col-2">
                        <div className="list-group" id="myList" role="tablist">
                        <a className="list-group-item list-group-item-action" data-toggle="tab" href="#1" role="tab">Set 1</a>
                        <a className="list-group-item list-group-item-action"  data-toggle="tab" href="#2" role="tab">Set 2</a>
                        <a className="list-group-item list-group-item-action"  data-toggle="tab" href="#3" role="tab">Set 3</a>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="tab-content">     
                            <div className="tab-pane" id="1" role="tabpanel" >
                                <div className="row"><h4>In total: 8 mins 22 seconds</h4></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=AK8oIyWMBEc&list=PLlhsp2Lr832tLeqQtZ6-JfcQpPfP1SILQ&index=2&t=0s" target="_blank">第3套中学生广播体操 - 舞动青春</a></div>             
                                <div className="row"><a href="https://www.youtube.com/watch?v=qBtmaEi8Nes&list=PLlhsp2Lr832tLeqQtZ6-JfcQpPfP1SILQ&index=2" target="_blank">广播体操 青春的活力</a></div>
                            </div>
                            <div className="tab-pane " id="2" role="tabpanel" >
                                <div className="row"><h4>In total: 6 mins 47 seconds</h4></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=KtFyUniQGSw&list=PLlhsp2Lr832tLeqQtZ6-JfcQpPfP1SILQ&index=3" target="_blank">第2套小学生广播体操 - 初升的太阳</a></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=Wa2e1UkVcew&list=PLlhsp2Lr832tLeqQtZ6-JfcQpPfP1SILQ&index=4" target="_blank">第2套小学生广播体操 - 雏鹰起飞</a></div>             
                            </div>
                            <div className="tab-pane" id="3" role="tabpanel">     
                                <div className="row"><h4>In total: 8 mins 48 seconds</h4></div>
                                <div className="row"><a href="https://www.youtube.com/watch?v=GuMIomW9wwg&list=PLlhsp2Lr832tLeqQtZ6-JfcQpPfP1SILQ&index=5" target="_blank">时代在召唤（第二套全国中学生广播体操）</a></div>             
                                <div className="row"><a href="https://www.youtube.com/watch?v=BdkH8wLBBBs&list=PLlhsp2Lr832tLeqQtZ6-JfcQpPfP1SILQ&index=6" target="_blank">第三套全国中学生广播体操——放飞理想</a></div>
                            </div>
                        </div>
                    </div>
                </div> 
                <ShowRandomPicture/>
            </div>
        )
    }
}

export default MorningComponent;